import { useContext } from "react";
import TasksContext from "../component/task";
import NavbarCountShow from "./NavbarCountShow";


function NavbarCountMap({sayi}) {
    

    
    return ( <>
{

sayi.map((numb,index)=>{

return <NavbarCountShow key={index} numb={numb}/>;

})

}


    </> );
}

export default NavbarCountMap;