

import { useState } from "react";
function UserShow({userdoc,admin}) {
    


 const [kadi, setKadi] = useState('');

 

    return ( <div>

{

userdoc.email == admin.email ? <div>{userdoc.kadi}</div>:<div></div>

}
    </div> );
}

export default UserShow;