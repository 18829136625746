
import { useEffect, useState } from "react";
import TasksContext from "./task";
import { useContext } from "react";
import EmojiMap from "./emojiler/EmojiMap";
import axios from "axios";
import EngelleMap from "./engelle/EngelleMap";
import { Link } from "react-router-dom";
function ChatShow({data,userdoc,DataTasks,users}) {


    const [emojiler, setEmojiler] = useState([]);

    const {admindelete,PmPost,apiurl} = useContext(TasksContext);

    const EmojiList=async(id)=>{
    
    const emoji = await axios.get(apiurl+`admin/api/EmojiList.php?code=${id}`);
    
    setEmojiler(emoji.data.data);
    
    //console.log(emoji.data.data);
    
    
    
    }
    useEffect(()=>{
    
      EmojiList(data.description);
      
      },[]);

const pm= "/Pm/";
const ben="/";


const Mesaj=(event)=>{

event.preventDefault();

    PmPost(users.displayName,users.uid,data.username,data.uid);

alert("İstek gönderildi kabul edilmesi bekleniyor");

}


    return (  <>



<div className="d-flex justify-content-start mb-4">
    <div className="img_cont_msg">
        
        <img src="https://w7.pngwing.com/pngs/806/764/png-transparent-sylvester-jr-tweety-cat-looney-tunes-cat.png" className="rounded-circle user_img_msg"/>
    
</div>
<div className="KullaniciAdi"><span className="usernameWrite">{data.username}</span></div>
<div className="msg_cotainer">


{ emojiler.name_emoji == data.description?<div>

<img src={emojiler.picture_emoji} className="iconEmoji"/>
</div>:<div>
    


    {data.description}
    
    
    </div>}

</div>

<EngelleMap users={users} data={data}/>
<span className="msg_time"> AM today </span>

{data.uid != users.uid ?

<div  className="formBox">

 <div className="pm_write">

        <form>
        
        <button className="btn btn-primary" onClick={Mesaj}>Mesaj</button>
        
        </form>
</div>

<div className="pm_write">
       
       <Link to={pm+users.uid+ben+data.uid}><button className="btn btn-dark">Pm</button></Link>

</div>
 

</div>





:<div></div>}
    </div>












<div>

</div>






    

    </>);
}

export default ChatShow;