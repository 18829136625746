import NickNameShow from "./NicknameShow";
import { useContext } from "react";
import TasksContext from "../task";
import Home from "../Home";

function NicknameMap({usertable}) {


    
    return ( <div>

{

usertable.map((tableuser,index)=>{

return <NickNameShow key={index} tableuser={tableuser}/>;


})

}

    </div> );
}

export default NicknameMap;