
import { useContext } from 'react';
import { SignCikis } from '../firebase';
import { auth,provider } from '../firebase';
import TasksContext from '../task';
function PbanShow({ip,admin,setAdmin,Setloading,setKullanici}) {
    
const {ipbanlama}= useContext(TasksContext);

//console.log(admin);
    if (ip.ip_no) {
    
        SignCikis(auth).then(()=>{
        
            setAdmin('');
            
            setKullanici('');
        
            //console.log("çıkış yapıldı");
           Setloading(false);
            
              }).catch(()=>{
            
            
            
              });
    
              ipbanlama();
       }
    else{
    
    
    
    }
    
    return ( <div>


    </div> );
}

export default PbanShow;