
function NavbarCountShow({numb}) {

    
    return ( <>
<span className="kirmizi_write">
{ 

numb[0]

}
</span>
    </> );
}

export default NavbarCountShow;