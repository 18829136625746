import { useContext } from "react";
import TasksContext from "../task";
import EngelleShow from "./EngelleShow";


function EngelleMap({users,data}) {
    
    const {admindelete} = useContext(TasksContext);


    return ( <div>

{

admindelete.map((admin,index)=>{

return <EngelleShow key={index} admin={admin} users={users} data={data}/>;


})

}


    </div> );
}

export default EngelleMap;