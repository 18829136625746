

import TasksContext from "../task";
import { useContext } from "react";
import { useState } from "react";
import EmojiMap from "../emojiler/EmojiMap";
import {useParams} from 'react-router-dom';
import axios from "axios";
function PmMuteShow({users,mutlu}) {
    const [text, setText] = useState('');
	const {TextPos,admindelete,AdminDelete,MuteBan,Online,OfflineUpdate,DataTasks,MessageDelete,Banefekt,ipbanlama,MessageDeleteoda,apiurl}= useContext(TasksContext);
	const params= useParams();

	const members= params.member;
	const members_two= params.membertwo;
// pmno post
const [pmnopost, setPmnopost] = useState([]);


// pm no post
	
	const Text=(event)=>{
	
		setText(event.target.value);


		
		}



    const HandleCanan=async(event)=>{
		
        event.preventDefault();
	
		if (text == "") {
		alert("Boş gönderilemez");
			
		}
		else{


		
		
		let chatBox = document.getElementById('home');
		chatBox.scrollTop = chatBox.scrollHeight;
	  
		
		const configs={

			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
			
			};
		
		const nesnem={
		
		 ozel_mesaj:users.displayName+":"+"    "+text
		
		
		
		}
		
		const pmpos= await axios.post(apiurl+`admin/api/PmnoPost.php?sender_chat_id=${members}&receiving_chat_id=${members_two}`,nesnem,configs);
		
		setPmnopost(pmpos);
	
		setText('');
		
		MuteBan();

		

	

			


		
		

			ipbanlama();
			Banefekt();

		}
		DataTasks();
		}
		

   
// open emoji

const [chatopen, setChatopen] = useState(false);
const HandleEmoji=()=>{

setChatopen(true);
    


    
     }

    
const HandleEmojiClose=()=>{


setChatopen(false);

}
   return ( <div>



{mutlu.email != users.email?<div></div>:<div>

<form onSubmit={HandleCanan}>
						<div className="card-footer">
							<div className="input-group">
							
								<div className="input-group-append">
								{chatopen?
									<span className="input-group-text attach_btn"  onClick={HandleEmojiClose}><i className="fas fa-paperclip"></i></span>

                                 :
								 <span className="input-group-text attach_btn"  onClick={HandleEmoji}><i className="fas fa-paperclip"></i></span>

								 
								  }
									</div>
                            

									
								<input  placeholder="Type your message--Emoji" className="form-control type_msg col" onChange={Text} value={text}/>
                            
							 
								<div className="input-group-append">
								

								
								</div>
							
								


							</div>
						</div>
						
				          
						
							
						
						</form>
</div>}
                       















                        {chatopen?<div>

<div className="EmojiBox"> {/* burasi emoji map */}
<EmojiMap users={users} />

</div>
</div>:<div></div>
}

</div>



    );
}

export default PmMuteShow;