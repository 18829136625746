
import axios from "axios";

import {useState,useEffect, useContext} from "react";
import TasksContext from "../task";
import {useParams} from 'react-router-dom';
import RoomschatMapping from "./RoomschatMapping";

function RoomschatMap({users}) {
    


    const params= useParams();

const members= params.member;

const [nik, setNik] = useState([]);


const {apiurl,apiNo,host,apikey,odamacik,setOdamacik} = useContext(TasksContext);

// burası rooms ototmatik mesajlar siliniyor

const [roomsure, setRoomsure] = useState([]);
const Roomsure=async()=>{
try {
  

const dataRoom=await axios.post(apiurl+`admin/api/RoomsureDelete.php?id=${members}`,{
    headers: {
      'Content-Type': 'application/json'
    }
  });

  setRoomsure(dataRoom);

} catch (error) {
  
console.log("Hatamız:"+error);

}
}



// burda oda şifre control yeri gerçekleşiyor

const [dondur, setDondur] = useState([]);

const HandleGiris=async()=>{
	


try {
    
const respon=await axios.get(apiurl+`admin/api/OdasifreControl.php?email=${users.email}&room_name=${members}`);

setDondur(respon.data.data);
//console.log(respon.data.data);
if (respon.data.data) {
    
    setOdamacik(true);

}
else{

    setOdamacik(false);
console.log("Bu odaya ait değilsiniz");



}

} catch (error) {

    console.log("Hata:"+error);

}


}


// burda oda şifre yeri gerçekleşiyor




// burası rooms otomatik mesajlar siliniyor

const Rooms=async()=>{

const room= await axios.get(host+`admin/api/Roomsfet.php?id=${members}`);

setNik(room.data.data);

}




useEffect(()=>{
const interval= setInterval(()=>{
    Rooms();
    Roomsure();
    HandleGiris();

},2000);
return ()=>{
clearInterval(interval);

};

},[nik]);


    return (<>
{odamacik ?

<RoomschatMapping users={users} nik={nik}/>
:<div></div>}
    </>  );
}

export default RoomschatMap;