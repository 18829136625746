import logo from './logo.svg';
import './App.css';
import {useEffect} from 'react';

import {useState} from 'react';
import { SignUp } from './component/firebase';
import { SignIn } from './component/firebase';
import { SignCikis } from './component/firebase';
import { auth,provider } from './component/firebase';
import {onAuthStateChanged} from 'firebase/auth';

import { Link, Routes,Route} from 'react-router-dom';
import AdminRoad from './component/AdminRoad';

import { signInWithPopup } from 'firebase/auth';
import { useContext } from 'react';
import TasksContext from './component/task';
import NavbarMenu from './navbar/NavbarMenu';
import Home from './component/Home';


import AdminHome from './component/AdminHome';
import Oda from './component/Oda';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Register from './register/Register';
import User from './component/User';
import UserShow from './component/UserShow';
import NicknameMap from './component/nickname/NicknameMap';

import BanMap from './component/ban/BanMap';
import PbanMap from './component/ipban/PbanMap';
import MuteMap from './component/mute/MuteMap';
import Homeuser from './component/users/Homeuser';
import TableMap from './component/userstable/TableMap';
import PmHome from './component/pm/PmHome';
import PmiletiHome from './component/pmileti/PmiletiHome';
import Roomhome from './component/rooms/Roomhome';

function App() {
  
 

const {tasks,fetchTasks,UserTable,usertable,Register,mute,setOnline,Tarih,admindelete}= useContext(TasksContext);

  const [authUser,SetAuthUser]=useState(null);
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const[loading,Setloading]=useState(true);
const [authAdmin,SetAdmin]=useState(null);

const [admin, setAdmin] = useState('');

const [error, setError] = useState('');


const [kullanici, setKullanici] = useState('');




const emails=(event)=>{
setEmail(event.target.value);


}


const passwords=(event)=>{
  setPassword(event.target.value);
  
  
  }

/*
const HandleGiris=(event)=>{

event.preventDefault();




SignIn(email,password).then(()=>{





 
  Setloading(false);

  
    }).catch(()=>{
  
setError('Giriş bilgileriniz yanlıştır');
console.log("Hatalı giriş");
    });






}

*/
//console.log(tasks);

  useEffect(()=>{

    UserTable();
   

  const listen=  onAuthStateChanged(auth,(user) =>{
   
 

    if (user) {
      
     
      setOnline(true);

      setKullanici(user);


setAdmin(user);


    SetAuthUser(user);
  

    Setloading(false);
  
   
   
    
    

    }
    else{
      setAdmin('');
    SetAuthUser('');
    setKullanici('');
    Setloading(false);
   

    }
    
    })


 
/*
SignUp("Baha","betmenlerdiyari@gmail.com","1234567890").then(()=>{

console.log("kayıt başarılı");

}).catch(()=>{

console.log("kayıt edilemedi");

});
*/


return ()=>{
  listen();
  //AdminListen();

  

  }
  

  

  

},[]);




/*
const Cikis=(event)=>{

event.preventDefault();

  SignCikis(auth).then(()=>{

setAdmin('');

//console.log("çıkış yapıldı");
Setloading(false);

  }).catch(()=>{



  });





}

*/


const [googleuser, setGoogleuser] = useState(null);

const HandleGoogle=()=>{


 signInWithPopup(auth,provider).then((result)=>{


  
  

Setloading(false);




    const  kediUser = result.user;

console.log(kediUser);

    setGoogleuser(kediUser);

    Tarih(kediUser.email);


Register(kediUser.displayName,kediUser.email,kediUser.uid);

console.log("kayıt başarılı");





  }).catch((err)=>{

console.log(err+"kayıt yapılamadı");

  });

  }







// mute map



const  users= auth.currentUser;




  return (
    <div className="App">


{loading ?<div>Bekleyiniz</div>:users?<div>
    




  



<NavbarMenu setAdmin={setAdmin} Setloading={Setloading} setKullanici={setKullanici} admin={admin} users={users}/>
    
<Routes>
<Route path='/oda' element={<Oda users={users}/>}/>
<Route path='/register' element={<Register/>}/>
<Route path='User' element={<User/>}/>


 <Route path="/" element={<Home users={users}/>}/>;



<Route path='Adminhome' element={<AdminHome/>}/>

<Route path="/Pm/:member/:membertwo" element={<PmHome users={users}/>}/>
<Route path="/Messages/:member" element={<PmiletiHome users={users}/>}/>
<Route path="/Rooms/:member" element={<Roomhome users={users}/>}/>
</Routes>

<NicknameMap usertable={usertable}/>







</div>:<div>
  








<Container className='loginKapsayici'>
      <Row>
        <Col xs={{ span: 12 }} className='colPadding'>
  
<div className='border'>
<h3 className='googleWrite'>Google ile giriş yapın</h3>
<img src="https://www.sohbet-yap.com/wp-content/uploads/2020/09/Chat-Sohbet.jpg" className='sohbetresim'/>
<button onClick={HandleGoogle} className='btn btn-success' 
 id="googlebutton">Chat Google Login Giriş</button>
</div>
</Col>
</Row>
</Container>


</div>




   

}



<BanMap admin={admin} setAdmin={setAdmin} Setloading={Setloading} setKullanici={setKullanici}/>

<PbanMap admin={admin} setAdmin={setAdmin} Setloading={Setloading} setKullanici={setKullanici}/>
<AdminRoad admin={admin}/>





    </div>
  );
}

export default App;
