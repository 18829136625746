
import UserShow from "./UserShow";
import TasksContext from "./task";
import { useContext } from "react";


function User({admin}) {

    const {usertable}=useContext(TasksContext);


    return ( <div>
{

usertable.map((userdoc,index)=>{

return <UserShow key={index} userdoc={userdoc} admin={admin}/>;


})

}



    </div> );
}

export default User;