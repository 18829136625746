
import TasksContext from "../task";
import { useContext } from "react";
import MuteOdaShow from "./MuteOdaShow";
function MuteOdaMap({users}) {
    const {mute} = useContext(TasksContext);
    
    return ( <div>

{

mute.map((mutlu,index)=>{

return <MuteOdaShow key={index} mutlu={mutlu} users={users}/>;

})

}

    </div> );
}

export default MuteOdaMap;