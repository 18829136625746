
import TasksContext from "../task";
import { useContext, useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import EmojiMap from "../emojiler/EmojiMap";
function MuteOdaShow({mutlu,users}) {
    
    const [text, setText] = useState('');
	const {aktarim,magazini,odamagazin,MuteBan,ipbanlama,Banefekt,MagazinForever,OdaPost}= useContext(TasksContext);

   

//console.log(magazini);

		// odalari cekme
const magazin="magazin";
const page="../oda/";

const [aktar, setAktar] = useState([]);


//oda cekme


    
  // odalari cekme
	

	const Text=(event)=>{
	
		setText(event.target.value);
		
		}
		
		


			
			
			
		
		const HandleCanans=(event)=>{
			
			

		event.preventDefault();
		
			
		
if (text == "") {
	

alert("Boş mesaj gönderilemez");

}
else{
	

		  let chatBox = document.getElementById('baha');
          chatBox.scrollTop = chatBox.scrollHeight;
		
		
		OdaPost(users.displayName,users.photoURL,text,users.uid);
		setText('');
		
		MuteBan();
	
		ipbanlama();
		Banefekt();
		}

		
	MagazinForever();
	
	
	
	
}






// open emoji

const [chatopen, setChatopen] = useState(false);
		const HandleEmoji=()=>{

setChatopen(true);
			


			
			 }
		
			
	const HandleEmojiClose=()=>{


setChatopen(false);

	}	

 return ( <div>


{mutlu.email != users.email?<div></div>:<div>

<form onSubmit={HandleCanans}>
						<div className="card-footer">
							<div className="input-group">
								<div className="input-group-append">
								{chatopen?
									<span className="input-group-text attach_btn"  onClick={HandleEmojiClose}><i className="fas fa-paperclip"></i></span>

                                 :
								 <span className="input-group-text attach_btn"  onClick={HandleEmoji}><i className="fas fa-paperclip"></i></span>

								 
								  }
									
								</div>
								<input  placeholder="Type your message..." className="form-control type_msg" onChange={Text} value={text}/>
								<div className="input-group-append">
								<button className="input-group-text send_btn" type="submit"><i className="fas fa-location-arrow"></i></button>
								</div>
							</div>
						</div>
						
				
						
							
						
						</form>
                        </div>}

						{chatopen?<div>
<div className="EmojiBox"><EmojiMap users={users} /></div>
</div>:<div></div>
}

    </div> );
}

export default MuteOdaShow;