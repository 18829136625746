

import EngelleMap from "../engelle/EngelleMap";
import { Link,Outlet} from "react-router-dom";
function ChatShows({nes,users}) {

    const pm= "/Pm/";

const one= nes.length-1;

return ( <>
{one?<div>
<div className="d-flex justify-content-start mb-4">
            <div className="img_cont_msg">
                
                <img src="https://w7.pngwing.com/pngs/806/764/png-transparent-sylvester-jr-tweety-cat-looney-tunes-cat.png" className="rounded-circle user_img_msg"/>
            
        </div>
           {/*
        <div className="KullaniciAdi"><span className="usernameWrite">
            
            
            </span></div>
           
*/}



        <div className="msg_cotainer">{nes}       {/* burasi dm ozel mesaj atma yazisi */}</div>
</div>
        




<div>
            
        
    
            
            
            </div>
        
        </div>
       
        :<div></div>

    
        }
      
            

 </> );
}

export default ChatShows;