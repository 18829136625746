
import ChatShow from "./ChatShow";
import User from "./User";
import TasksContext from "./task";
import { useContext } from "react";
function ChatMap({users}) {
    
   const {datam,DataTasks}= useContext(TasksContext);



    return ( <div>



{

datam.map((data,index)=>{

return <ChatShow key={index} data={data} DataTasks={DataTasks} users={users} />;
  


})

}






    </div> );

    }
export default ChatMap;