import RoomnavShow from "./RoomnavShow";

function RoomnavMap({nav}) {
    return (  <div>


{

nav.map((nv,index)=>{

return <RoomnavShow key={index} nv={nv}/>;

})

}

    </div>);
}

export default RoomnavMap;