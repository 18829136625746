
import { useContext } from 'react';
import RoomadminShow from './RoomadminShow';
import TasksContext from '../task';
function Roomadmin({users}) {

    const {adminrole} = useContext(TasksContext);

    return (  <div>


{

adminrole.map((admin,index)=>{

return <RoomadminShow  admin={admin} key={index} users={users}/>;

})
   
}
   </div>);
}

export default Roomadmin;