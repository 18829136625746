import { Link } from "react-router-dom";

function RoomnavShow({nv}) {
    
    
const url= "/Rooms/";

    return ( <>
<div className="hizala">

<Link to={url+nv.url}>    
{

nv.name

}
</Link>
</div>
<br></br>
    </> );
}

export default RoomnavShow;