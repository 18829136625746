import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { SignCikis } from '../component/firebase';
import { auth,provider } from '../component/firebase';
import TasksContext from '../component/task';
import { useContext } from 'react';
import User from '../component/User';
import Homeuser from '../component/users/Homeuser';
import { Link } from 'react-router-dom';
import NavbarCountMap from './NavbarCountMap';
import { useEffect,useState } from 'react';
import axios from 'axios';
import RoomnavMap from './roomsnav/RoomnavMap';
function NavbarMenu({setAdmin,Setloading,setKullanici,admin,users}) {
 
 const{usertable,online,setOnline,apiurl,Navbars,nav} = useContext(TasksContext);
 
 
  const Cikis=(event)=>{

    event.preventDefault();
    
    setOnline(false);

      SignCikis(auth).then(()=>{
    
    setAdmin('');
    
    setKullanici('');

    //console.log("çıkış yapıldı");
   Setloading(false);
    
      }).catch(()=>{
    
    
    
      });
    
    
    
    
    
    }
const me="/Messages/";
    
// pm count

const [sayi, setSayi] = useState([]);
const PmCount=async()=>{

  const Sayi= await axios.get(apiurl+`admin/api/PmCount.php?receiving_chat_id=${users.uid}`);

setSayi(Sayi.data.data);

}

useEffect(()=>{

PmCount();

},[]);

// pm count




  return (
    <div>

<Navbar bg="primary" variant="dark" expand="lg" >
  <Navbar.Brand href="#home">Menü</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/"><span className='link'>Home</span></Nav.Link>
      <Nav.Link href="/oda" ><span className='link'>oda-1</span></Nav.Link>

  


      <NavDropdown  id="basic-nav-dropdown" title="Admin">

        <NavDropdown.Item href=""><User usertable={usertable} admin={admin}/></NavDropdown.Item>
       
        <NavDropdown.Item href={me+users.uid}>Message box<NavbarCountMap sayi={sayi}/></NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href=""><RoomnavMap nav={nav}/></NavDropdown.Item>
      </NavDropdown>

      


      <Nav.Link href="/"><span className='link'><button onClick={Cikis} className='logoutButton'>Çıkış</button></span></Nav.Link>

    </Nav>
   
  </Navbar.Collapse>
</Navbar>


    </div>
  );
}
export default NavbarMenu;