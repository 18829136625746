
import {useParams} from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import PmiletiMap from './PmiletiMap';
import TasksContext from "../task";
function PmiletiHome({users}) {
  
    const {apiurl} =useContext(TasksContext);
const params= useParams();

const members = params.member;

const [liste, setListe] = useState([]);
const message=async()=>{

const mes= await axios.get(apiurl+`admin/api/PmistekList.php?receiving_chat_id=${members}`);

setListe(mes.data.data);

//console.log(mes.data.data);


}    

useEffect(()=>{

message();

},[]);
    
    return ( <>

{
users.uid == members?<div>
<PmiletiMap users={users} liste={liste}/>
</div>:
<div></div>
}

    </> );
}

export default PmiletiHome;