import Home from "../Home";

function NickNameShow() {
    


    return ( <div>



    </div> );
}

export default NickNameShow;