import PmiletiShow from "./PmiletiShow";

function PmiletiMap({liste,users}) {
    
    
    return ( <div>
{

liste.map((list,index)=>{

return <PmiletiShow key={index} list={list} users={users}/>;

})

}


    </div> );
}

export default PmiletiMap;