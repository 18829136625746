import { useContext } from "react";
import TasksContext from "../task";

import { useState } from "react";
function EngelleShow({admin,users,data}) {
    
const {Engelle,EngelOpen,Engellelog,ApiOpen}= useContext(TasksContext);


const [gizli, setGizli] = useState('');


const [gizliopen, setGizliopen] = useState('');








const HandleEngel=(event)=>{

event.preventDefault();

setGizli(data.uid);

Engelle(data.uid);
Engellelog(data.username,users.displayName);

alert("Kullancı engellendi");

}

const HandleEngelOpen=(event)=>{

event.preventDefault();

EngelOpen(data.uid);
setGizliopen(data.uid);
ApiOpen(data.username,users.displayName);

alert("Kullanıcının engeli açıldı");

}


    return ( <div>

{

admin.email == users.email ?<div>
    <div className="formBox">
<form>
<input type="hidden" value={gizli}/>
    <button className="btn btn-success" onClick={HandleEngelOpen}>Engeli aç</button>
</form>

</div>
<div  className="formBox">
<form>
<input type="hidden" value={gizliopen}/>
    <button className="btn btn-danger" onClick={HandleEngel}>Engelle</button>
</form>
</div>



</div>:<div>

</div>

    }

    </div> );
}

export default EngelleShow;