import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useContext, useState } from 'react';
import TasksContext from '../task';
function PmiletiShow({list,users}) {
    
const {apiurl} = useContext(TasksContext);

    
const Pm="/Pm/";

const [active, setActive] = useState([]);
const Active=async()=>{

//event.preventDefault();

  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const nesnem={
 sender_chat_id:list.sender_chat_id,
  receiving_chat_id:list.receiving_chat_id,



}

const Insert= await axios.post(apiurl+'admin/api/Pmactive.php',nesnem,configs);

setActive(Insert);



}

const [delet, setDelet] = useState([]);

const MessageDelete=async()=>{


  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const nesnem={
 sender_chat_id:list.sender_chat_id,
  receiving_chat_id:list.receiving_chat_id,



}

const deleti= await axios.post(apiurl+'admin/api/DeletePm.php',nesnem,configs);

setDelet(deleti);

}


    return ( <div>

<Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th>Mesaj gönderen</th>
          <th>Mesajı gör</th>
          <th>Mesaj isteğini kabul et</th>
          <th>Mesajı sil</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{list.sender}</td>
          <td>

<Link to={Pm+list.sender_chat_id+"/"+users.uid}><button className='btn btn-danger'>Mesaja git</button></Link>

          </td>

{list.active == 0?

         <td>

<form>

<button className='btn btn-success' onClick={Active}>Kabul et</button>

</form>

         </td>

:<td>Mesaj isteği kabul edildi</td>}


<td>

<form>
<button className='btn btn-info' onClick={MessageDelete}>Mesajı Sil</button>

</form>

</td>
        </tr>
      
      </tbody>
    </Table>


    </div> );
}

export default PmiletiShow;