import { createContext, useEffect,useRef } from 'react';
import { useState } from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {Outlet} from "react-router-dom";
const TasksContext = createContext();

function Provider({ children }) {

 

  const [tasks, setTasks] = useState([]);
  const [pos, setPos] = useState([]);
  const [datam, setDatam] = useState([]);

  const [odamacik, setOdamacik] = useState(false);

//const [odam, setOdam] = useState([]);


const [sayfa, setSayfa] = useState([]);


// api web site url

//const apiurl='http://localhost/react-fireabase/login/';
const apiurl='https://chat.yazilimdukkani.xyz/';
const apiNo= '?id=bahazengin';

//const host="http://localhost:3004/";

const host="https://chat.yazilimdukkani.xyz/";
// api web site url

//burası apikey
const apikey="bahazengin";
// burası apikey


  const fetchTasks = async () => {
    const response = await axios.get(apiurl+'AdminFetch.php'+apiNo);
    //debugger;
    setTasks(response.data.data);
//console.log(response.data);



  };
  




//odalarin postu
/*
const OdaPost=async(aciklama)=>{
  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const OdaRoom= await axios.post('http://localhost:3005/oda',{
aciklama,


});

setOdam(OdaRoom);
}
*/


//odalarin postu



// mesajları otomatik silme postu
const [messages, setMessages] = useState([]);
const messageData=async()=>{
try {
  

const dataMessage=await axios.post(apiurl+`admin/api/MesajsureDelete.php?code=${apikey}`,{
    headers: {
      'Content-Type': 'application/json'
    }
  });

setMessages(dataMessage);

} catch (error) {
  
console.log("Hatamız:"+error);

}
}
// mesajları otomatik silme postu



const DataTasks=async()=>{
 
const datalar=await axios.get(apiurl+`admin/api/Chatcek.php?code=${apikey}`);


setDatam(datalar.data.data);


//console.log(datalar.data);


}



useEffect(() => {
  const intervals = setInterval(() => {
    DataTasks(); 
    messageData();
  }, 3000);

  return () => {
    clearInterval(intervals);
  
  };
}, [datam]);




//oda post
const [room, setRoom] = useState([]);
const OdaPost=async(username,picture,description,uid)=>{


  try {
    

  

const OdaRoom= await axios.post(apiurl+`admin/api/Odasohbet.php?code=${apikey}`,{
username,
picture,
description,
uid,


},
{
  headers: {
    'Content-Type': 'application/json'
  }
});

setRoom(OdaRoom);

} catch (error) {
    
console.log("Hangisi boş".error);

}
}



//oda post


  

//oda cekme salon-2
const params= useParams();

const members= params.memberId;
const [SearchBlog, setSearchBlog] = useState('');

const [aktarim, setAktarim] = useState([]);

const [roomoda, setRoomoda] = useState([]);
const [magazini, setMagazin] = useState([]);
const [magazindondur, setMagazindondur] = useState([]);
const RoomFetch=async()=>{

  const responsem=await axios.get(apiurl+'admin/api/ChatOda.php'+apiNo);


  setRoomoda(responsem.data.data);

 // console.log(responsem.data.data);

setAktarim(responsem.data.data);

//const zin= await axios.get(responsem.data.data[0].api);

setMagazin(responsem.data.data[0].api);
//console.log(responsem.data.data[0].api);


}
useEffect(()=>{
  
	RoomFetch();
	

	
   
	},[]);


// burda oda belli bir mesaj sayısına ulaştığında otomatik siliniyor
const [odamessage, setOdamessage] = useState([]);
const Odamessagedata=async()=>{
try {
  

const dataOdamessage=await axios.post(apiurl+`admin/api/MessageodaDelete.php?code=${apikey}`,{
    headers: {
      'Content-Type': 'application/json'
    }
  });

  setOdamessage(dataOdamessage);

} catch (error) {
  
console.log("Hatamız:"+error);

}
}


// burda oda belli bir mesaj sayısına ulaştığında otomatik siliniyor


const [odamagazin,setOdamagazin] = useState([]);

const MagazinForever=async()=>{

 

  

const z= await axios.get(apiurl+`admin/api/Odafet.php?code=${apikey}`);

setOdamagazin(z.data.data);

  

 
  
}
useEffect(() => {
  const interval = setInterval(() => {
    MagazinForever(); 
    Odamessagedata();

  }, 3000);

  return () => {
    clearInterval(interval);
  
  };
}, [odamagazin]);







const TextPos=async(username,picture,description,uid)=>{

  try {
    const response = await axios.post(apiurl+`admin/api/Chatsohbet.php?code=${apikey}`, {
      username,
      picture,
      description,
      uid
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    setPos(response.data);
  } catch (error) {
    console.error("POST request error: ", error);
  }


};

// oda cekme salon-2

// register post

const [register, setRegister] = useState([]);
const [registercontrol, setRegistercontrol] = useState('');
const [registererror, setRegistererror] = useState('');
const Register=async(kadi,email,uid)=>{

  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const nesne={

kadi,
email,
uid,


}


const reg= await axios.post(apiurl+"admin/api/RegisterPost.php",nesne,configs);

  

setRegister(reg);

//console.log(reg.data);



setRegistercontrol(reg.data);








}


// register post




// register picture cek

const [picture, setPicture] = useState([]);

const regelationPicture=async()=>{

const responsePicture= await axios.get(apiurl+'admin/api/Registerpicture.php');

setPicture(responsePicture.data.data);

//console.log(responsePicture.data.data);


}

useEffect(()=>{

regelationPicture();


},[]);


// register picture cek

//user table cek
const [usertable, setuserTable] = useState([]);
const [online, setOnline] = useState(false);
const UserTable = async () => {
  const responsem = await axios.get(apiurl+'admin/api/UserTable'+apiNo);
  //debugger;
  setuserTable(responsem.data.data);
//console.log(responsem.data.data);

};

useEffect(()=>{
  UserTable();
  function handleOnlineStatus(){

setOnline(true);

  }
  
  function handleOfflineStatus(){

    setOnline(false);
    
      }
      

  window.removeEventListener("offline", handleOfflineStatus);
  window.removeEventListener("online", handleOnlineStatus);


return ()=>{

  window.removeEventListener("offline", handleOfflineStatus);
  window.removeEventListener("online", handleOnlineStatus);



};



},[]);


// user table cek


// admin silme  yetkisi

const [admindelete, setAdmindelete] = useState([]);

const AdminDelete=async()=>{

const AdminRole= await axios.get(apiurl+'admin/api/AdminRole.php'+apiNo);

setAdmindelete(AdminRole.data.data);


}

useEffect(()=>{

  AdminDelete();
  


  },[]);




// admin silme yetkisi


//coadmin silme yetkisi


const [adminrole, setAdminrole] = useState([]);

const AdminRole=async()=>{

const adminroles= await axios.get(apiurl+'admin/api/AdminroleRoom.php');

setAdminrole(adminroles.data.data);


}

useEffect(()=>{


  AdminRole();


  },[]);
//coadmin silme yetkisi


//--------------------------------------------------------------------------------------------------------------------------- //


//admin veriyi silme  anasayfa chat paneli

const [messagedelete, setMessagedelete] = useState([]);

const MessageDelete=async()=>{


try {
  


const responsedelete= await axios.post(apiurl+'admin/api/AdmindataDelete.php');




setMessagedelete(responsedelete);




} catch (error) {
  
console.log("Hata:"+error);

}
}



// admin veriyi silme anasayfa chat paneli


//admin oda silme 

const [messagedeleteoda, setMessagedeleteoda] = useState([]);

const MessageDeleteoda=async()=>{


try {
  


const responsedeletem= await axios.post(apiurl+'admin/api/RoomClear.php');




setMessagedeleteoda(responsedeletem);
} catch (error) {


console.log("Hatalı işlem:"+error);

  
}

}



// admin oda silme



// ADMİN BAN SİMİLASYONU

const [ban, setBan] = useState([]);


const Banefekt=async()=>{


    
    
    
    


const ban= await axios.get(apiurl+'admin/api/Adminban'+apiNo);

setBan(ban.data.data);

//console.log(ban.data.data);


}

useEffect(()=>{

Banefekt();

},[]);


// ADMİN BAN SİMİLASYONU

// ipban similasyonu

const [ipban, setIpban] = useState([]);

const ipbanlama=async()=>{

const banips= await axios.get(apiurl+'admin/api/Banip'+apiNo);

setIpban(banips.data.data);


}

useEffect(()=>{

ipbanlama();


},[]);


//ipban similasyonu


// muteban

const [mute, setMute] = useState([]);

const MuteBan=async()=>{

const Mutem= await axios.get(apiurl+'admin/api/Muteban'+apiNo);

setMute(Mutem.data.data);


}

useEffect(()=>{

MuteBan();

},[]);
// muteban


// login date guncelleme

const [tarih, setTarih] = useState([]);

const Tarih=async(email)=>{

  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const nesnem={


email,


}

const tarihdate= await axios.post(apiurl+'admin/api/Onlinedate.php',nesnem,configs);

setTarih(tarihdate.data.data);



}



// login date guncelleme


// Emoji all

const [emojiall, setEmojiall] = useState([]);
const EmojiAll=async()=>{


const Emojiall= await axios.get(apiurl+'admin/api/Emojiall.php');

setEmojiall(Emojiall.data.data);


}

useEffect(()=>{

EmojiAll();


},[]);


// Emoji all


// Admin engelle

const [engel, setEngel] = useState([]);
const Engelle=async(uid)=>{


  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const nesnem={


uid


}

const engel= await axios.post(apiurl+'admin/api/Engelle.php',nesnem,configs);

setEngel(engel);


}


const [logkayit, setLogkayit] = useState([]);

const Engellelog=async(username,displayName)=>{

  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const nesnem={


username,
displayName,


}

const log= await axios.post(apiurl+'admin/api/Logpost.php',nesnem,configs);

setLogkayit(log);

}

// Admin engelle


//Mute open


const [engelOpen, setEngelopen] = useState([]);
const EngelOpen=async(uid)=>{


  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const nesnem={


uid


}

const engelop= await axios.post(apiurl+'admin/api/MuteAc.php',nesnem,configs);

setEngelopen(engelop);


}

const [apiopen, setApiopen] = useState([]);
const ApiOpen=async(username,displayName)=>{

  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const nesnem={


username,
displayName,


}


const op= await axios.post(apiurl+'admin/api/Logopen.php',nesnem,configs);

setApiopen(op);

}


//Mute open

// Users place
const [usertab, setUsertab] = useState([]);
const UsersPlace =async()=>{

const userplace= await axios.get(apiurl+'admin/api/UsersPlace.php'+apiNo);


setUsertab(userplace.data.data);

}

useEffect(()=>{

UsersPlace();


},[]);

//Users place


// Admin bans
const [adminban, setAdminban] = useState([]);
const Adminban=async(uid)=>{

  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const nesnem={


uid


}

const ban=await axios.post(apiurl+'admin/api/AdminBans.php',nesnem,configs);


setAdminban(ban);

}


// Admin bans


// open the ban
const [openban, setOpenban] = useState([]);
const Adminopen=async(uid)=>{

  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const nesnem={


uid


}

const bans=await axios.post(apiurl+'admin/api/AdminbanOpen.php',nesnem,configs);


setOpenban(bans);

}


// open the ban


// pm post
const [pmpost, setPmpost] = useState([]);

const PmPost=async(sender,sender_chat_id,receiving,receiving_chat_id)=>{

  const configs={

    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    
    };

const nesnem={

  sender,
  sender_chat_id,
  receiving,
  receiving_chat_id,



}

const pmpos= await axios.post(apiurl+'admin/api/Pm.php',nesnem,configs);

setPmpost(pmpos);


}


// pm post


// navbar

const [nav, setNav] = useState([]);
const Navbars=async()=>{

const ap= await axios.get(apiurl+'admin/api/Navbarurl.php');

setNav(ap.data.data);


}

useEffect(()=>{

Navbars();

},[]);
// navbar


// ziyaretci sayac
const [guest, setGuest] = useState([]);

const Sayac=async()=>{

await axios.get(apiurl+'admin/api/Ziyaretci.php');

//setGuest(say.data);
//console.log(say.data.data);
}

useEffect(()=>{

  Sayac();

},[]);

// ziyaretci sayac

const sharedValuesAndMethods = {
 
  
    apiurl,
    apiNo,
    host,
    tasks,
    fetchTasks,
    TextPos,
    DataTasks,
    datam,
    aktarim,
    magazini,
    MagazinForever,
    odamagazin,
    setOdamagazin,
    Register,
    register,
    registercontrol,
    registererror,
    regelationPicture,
    picture,
    UserTable,
    usertable,
    AdminDelete,
    admindelete,
    MessageDelete,
    ban,
    Banefekt,
    MessageDeleteoda,
    ipbanlama,
    ipban,
    MuteBan,
    mute,
    online,
    setOnline,
    Tarih,
    EmojiAll,
    emojiall,
  
    Engelle,
    OdaPost,
    engelOpen,
    EngelOpen,
    UsersPlace,
    usertab,
    Adminban,
    Adminopen,
    PmPost,
    Navbars,
    nav,
    Engellelog,
    ApiOpen,
    apikey,
    odamacik,
    setOdamacik,
    adminrole,
    setAdminrole
   
  
    
   
   
   
 
  
   
  

   
  };
  return (
    <TasksContext.Provider value={sharedValuesAndMethods}>
      {children}
     
    </TasksContext.Provider>
    
  );
}

export { Provider };
export default TasksContext;
