
import { useContext } from "react";
import EngelleMap from "../engelle/EngelleMap";
import TasksContext from "../task";
import { useState,useEffect } from "react";
import RoomsengelMap from "./RoomsengelMap";
import axios from "axios";
import { Link } from "react-router-dom";

function RoomschatShow({n,users}) {

  const {apiurl} = useContext(TasksContext);  

    const [emojiler, setEmojiler] = useState([]);
    const EmojiList=async(id)=>{
    
    const emoji = await axios.get(apiurl+`admin/api/EmojiList.php?code=${id}`);
    
    setEmojiler(emoji.data.data);
    

    
    
    
    }
    useEffect(()=>{
    
      EmojiList(n.description);
      
      },[]);
    


return (<>


<div className="d-flex justify-content-start mb-4">
    <div className="img_cont_msg">
        
        <img src={n.picture} className="rounded-circle user_img_msg"/>
    
</div>

<div className="KullaniciAdi"><span className="usernameWrite">{n.username}</span></div>

<div className="msg_cotainer">
{

n.description == emojiler.name_emoji?<div>
<img src={emojiler.picture_emoji} className="iconEmoji"/>

</div>:<div>
{n.description}
</div>

}




</div>


<span className="msg_time"> AM today </span>





</div>




    </>  );
}

export default RoomschatShow;