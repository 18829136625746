import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Link, Routes,Route} from 'react-router-dom';
import {BrowserRouter} from 'react-router-dom';
import { Provider } from './component/task';

import AdminHome from './component/AdminHome';
import Oda from './component/Oda';
import Home from './component/Home';
import Register from './register/Register';
import User from './component/User';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  //<React.StrictMode>
    

    <Provider>
      
<BrowserRouter>


    <App />
    </BrowserRouter>
    
    
    </Provider>
  
    

    
  //</React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
