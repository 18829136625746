import { useContext } from "react";
import TasksContext from "../task";
import AdminShow from "./AdminShow";

function AdminMap({users}) {


    const {admindelete} = useContext(TasksContext);

    return ( <div>


<AdminShow admindelete={admindelete} users={users}/>






    </div>  );
}

export default AdminMap ;