
import { useContext, useEffect, useState } from "react";
import TasksContext from "../task";
import EmojiMap from "../emojiler/EmojiMap";
import axios from "axios";
import {useParams} from 'react-router-dom';
import EmojiPicker from 'emoji-picker-react';
function RoomsmuteShow({users}) {
    

	const params= useParams();

	const members= params.member;

    const [text, setText] = useState('');
	const {TextPos,admindelete,AdminDelete,MuteBan,Online,OfflineUpdate,DataTasks,MessageDelete,Banefekt,ipbanlama,MessageDeleteoda,apiurl,host,apikey}= useContext(TasksContext);
	
	
//burda muteleme işlemi yapılıyor


const [don, setDon] = useState([]);


const Handlegoster=async()=>{

try {
    
const response=await axios.get(apiurl+`admin/api/RoomsmuteEngel.php?email=${users.email}&room_name=${members}`);
setDon(response.data.data);

console.log(response.data.data);

} catch (error) {
    
	console.log("Hata:"+error);
}



}

useEffect(()=>{

Handlegoster();

},[]);
// burda mutleme işlemi yapılıyor


	
	const Text=(event)=>{
	
		setText(event.target.value);


		
		}


		const [textpost, setTextpost] = useState([]);
const [uidpos, setUidpos] = useState([]);

    const HandleCanan=async(event)=>{
		
        event.preventDefault();
	
		if (text == "") {
		alert("Boş gönderilemez");
			
		}
		else{


		
		
		let chatBox = document.getElementById('home');
		chatBox.scrollTop = chatBox.scrollHeight;
	  
		const configs={

			headers: {'Content-Type': "application/json"}
			
			};
		
		const nesnem={
		username: users.displayName,
		picture: users.photoURL,
		description: text,
		uid: users.uid,
        chat_id:members
		
		
		
		}
		try {
			
		
		const pmpos= await axios.post(host+`admin/api/RoomuidPos.php?code=${apikey}`,nesnem,configs);
		
		setTextpost(pmpos);
		

	

	

		setText('');
		
		MuteBan();

		Handlegoster();

	

			


		
		

			ipbanlama();
			Banefekt();
		} catch (error) {
			console.log("Post hatası:"+error);
		}
		}
		DataTasks();
		
		}
		

// open emoji

const [chatopen, setChatopen] = useState(false);
		const HandleEmoji=()=>{

setChatopen(true);
			


			
			 }
		
			
	const HandleEmojiClose=()=>{


setChatopen(false);

	}	
    return ( <>


{don ?<div>
<form onSubmit={HandleCanan}>
						<div className="card-footer">
							<div className="input-group">
							
								<div className="input-group-append">
								{chatopen?
									<span className="input-group-text attach_btn"  onClick={HandleEmojiClose}><i className="fas fa-paperclip"></i></span>

                                 :
								 <span className="input-group-text attach_btn"  onClick={HandleEmoji}><i className="fas fa-paperclip"></i></span>

								 
								  }
									</div>
                            

									
								<input  placeholder="Type your message--Emoji" className="form-control type_msg col" onChange={Text} value={text}/>
                            
							 
								<div className="input-group-append">
								

								
								</div>
							
								


							</div>
						</div>
						
				          
						
							
						
						</form>

                       </div> :<div></div> }








					   







{chatopen?<div>
<div className="EmojiBox"><EmojiMap users={users} /></div>
</div>:<div></div>
}

    </> );
}

export default RoomsmuteShow;