import AdminDeleteShow from "./AdminDeleteShow";
import TasksContext from "../task";
import { useContext } from "react";
function AdminDeleteMap({users}) {

  const {admindelete} = useContext(TasksContext);

    return ( <div>

{

admindelete.map((admintemizle,index)=>{

return <AdminDeleteShow key={index} admintemizle={admintemizle} users={users}/>;


})


}
        
    </div> );
}

export default AdminDeleteMap;