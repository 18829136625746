

import AdminHome from './AdminHome';
import TasksContext from './task';
import { useContext } from 'react';





function AdminRoad({admin}) {
    



    const { usertable} = useContext(TasksContext);
    return ( <div>
{

usertable.map((task,index)=>{

return <AdminHome key={index} task={task} admin={admin} />;


})

}


    </div> );
}

export default AdminRoad;