
import { useEffect, useState } from "react";
import TasksContext from "../task";
import { useContext } from "react";

import axios from "axios";
import EngelleMap from "../engelle/EngelleMap";
import { Link,Outlet} from "react-router-dom";
import {useParams} from 'react-router-dom';
import ChatMaps from "./ChatMaps";
function ChatpmShow({users}) {
const {apiurl} =useContext(TasksContext);

    const params= useParams();

const members= params.member;
const members_two= params.membertwo;
const [pmId,setPmId] = useState([]);

const IdData=async()=>{

  const Pm=  await axios.get(apiurl+`admin/api/Pmsplit.php?sender_chat_id=${members}&receiving_chat_id=${members_two}`);


setPmId(Pm.data.split("."));

//console.log(Pm.data.split("<br>"));

}


    
useEffect(() => {
    const intervals = setInterval(() => {
        IdData(); 
    }, 4000);
  
    return () => {
      clearInterval(intervals);
    
    };
  }, [pmId]);

const pm= "/Pm/";
    return ( <>




        
     {users.uid != members && users.uid != members_two?<div><span className="beyaz_write">Bu sayfayı görme yetkiniz yoktur..</span></div>:
     
     
<ChatMaps pmId={pmId} users={users}/>

}


     
        </>
         );
}

export default ChatpmShow;