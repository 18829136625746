import { useContext } from "react";
import TasksContext from "../task";
import OnlineShow from "./OnlineShow";

function OnlineMap({users}) {



//console.log(usertable);

    return ( <div>



    </div> );
}

export default OnlineMap;