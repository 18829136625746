
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import {getAuth,createUserWithEmailAndPassword,updateCurrentUser,signInWithEmailAndPassword,signOut,GoogleAuthProvider} from "firebase/auth";


   // const[loading,Setloading]=useState(true);

const firebaseConfig = {
    apiKey: "AIzaSyCrtPoUhOWqe6QslNGDWIyRJkYzhoYXPgA",
    authDomain: "products-fca5f.firebaseapp.com",
    projectId: "products-fca5f",
    storageBucket: "products-fca5f.appspot.com",
    messagingSenderId: "314982688668",
    appId: "1:314982688668:web:36fcb223ce33050aef0a59",
    measurementId: "G-NY01EK1RJV"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  export const auth= getAuth(app);

export const provider= new GoogleAuthProvider();

  export const SignUp=async(name,email,password)=>{

   await createUserWithEmailAndPassword(auth,email,password);
await updateCurrentUser(auth,{displayName:name});

  };

  export  const SignIn=async(email,password)=>{

   await signInWithEmailAndPassword(auth,email,password);


  }

  export const SignCikis=async ()=>{

await signOut(auth);


  }



  