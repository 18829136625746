import Home from "../Home";
import { useContext } from "react";
import TasksContext from "../task";
import Oda from '../Oda';
import { Link, Routes,Route} from 'react-router-dom';
function AdminDeleteShow({admintemizle,users}) {
    
const {MessageDelete,MessageDeleteoda} = useContext(TasksContext);


const HandleDelete=()=>{


    MessageDelete();
    MessageDeleteoda();



}



    return ( <div>

{

admintemizle.email  == users.email?<div>

<form>
<button className="btn btn-success" onClick={HandleDelete} type="submit">OdaTEMİZLE</button>

</form>

</div>:<div>



</div>

}

    </div> );
}

export default AdminDeleteShow;