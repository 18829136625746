import Home from "../Home";
import EmojiMap from "../emojiler/EmojiMap";

import TasksContext from "../task";
import { useContext } from "react";
import { useState } from "react";
function MuteShow({mutlu,users}) {


	const [text, setText] = useState('');
	const {TextPos,admindelete,AdminDelete,MuteBan,Online,OfflineUpdate,DataTasks,MessageDelete,Banefekt,ipbanlama,MessageDeleteoda}= useContext(TasksContext);
	
	
	
	const Text=(event)=>{
	
		setText(event.target.value);


		
		}



    const HandleCanan=(event)=>{
		
        event.preventDefault();
	
		if (text == "") {
		alert("Boş gönderilemez");
			
		}
		else{


		
		
		let chatBox = document.getElementById('home');
		chatBox.scrollTop = chatBox.scrollHeight;
	  
		
		
		TextPos(users.displayName,users.photoURL,text,users.uid);
		setText('');
		
		MuteBan();

		

	

			


		
		

			ipbanlama();
			Banefekt();

		}
		DataTasks();
		}
		

// open emoji

const [chatopen, setChatopen] = useState(false);
		const HandleEmoji=()=>{

setChatopen(true);
			


			
			 }
		
			
	const HandleEmojiClose=()=>{


setChatopen(false);

	}		 


return (<div>


{mutlu.users}

{mutlu.email != users.email?<div></div>:<div>
<form onSubmit={HandleCanan}>
						<div className="card-footer">
							<div className="input-group">
							
								<div className="input-group-append">
								{chatopen?
									<span className="input-group-text attach_btn"  onClick={HandleEmojiClose}><i className="fas fa-paperclip"></i></span>

                                 :
								 <span className="input-group-text attach_btn"  onClick={HandleEmoji}><i className="fas fa-paperclip"></i></span>

								 
								  }
									</div>
                            

									
								<input  placeholder="Type your message--Emoji" className="form-control type_msg col" onChange={Text} value={text}/>
                            
							 
								<div className="input-group-append">
								

								
								</div>
							
								


							</div>
						</div>
						
				          
						
							
						
						</form>

                       </div>  }
















{chatopen?<div>
<div className="EmojiBox"><EmojiMap users={users} /></div>
</div>:<div></div>
}

    </div>  );
}

export default MuteShow;