
import { useContext } from 'react';
import { SignCikis } from '../firebase';
import { auth,provider } from '../firebase';
import TasksContext from '../task';
function BanShow({banignore,admin,setAdmin,Setloading,setKullanici}) {
    
//console.log(admin.email);
const {Banefekt} = useContext(TasksContext);
   if (banignore.email == admin.email) {
    
    SignCikis(auth).then(()=>{
    
        setAdmin('');
        
        setKullanici('');
    
        //console.log("çıkış yapıldı");
       Setloading(false);
        
          }).catch(()=>{
        
        
        
          });

          Banefekt();
   }
else{



}


    return ( <div>



    </div> );
}

export default BanShow;