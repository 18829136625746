import {  useContext } from "react";
import TasksContext from "../task";
import BanShow from "./BanShow";


function Ban({admin,setAdmin,Setloading,setKullanici}) {
    
const {ban} = useContext(TasksContext);


    return ( <div>

{

ban.map((banignore,index)=>{

return <BanShow key={index} banignore={banignore} admin={admin} setAdmin={setAdmin} Setloading={Setloading} setKullanici={setKullanici}/>;


})

}


    </div> );
}

export default Ban;