

import Home from "./Home";
function AdminHome({task,admin}) {
    
//console.log(task.email);
//console.log(admin.email);

    return ( <div>





{

task.email == admin.email ? <div></div>:<div></div>

}



    </div> );
}

export default AdminHome;