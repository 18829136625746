import RoomschatMap from "../roomschat/RoomschatMap";
import RoomsmuteMap from "../roomsmute/RoomsmuteMap";
import RoomadminMap from "../roomsadmin/RoomadminMap";
import { useContext, useEffect,useState } from "react";
import {useParams} from 'react-router-dom';
import TasksContext from "../task";
import axios from "axios";

function Roomhome({users}) {
    
// lock
const {apiurl,setOdamacik,odamacik} = useContext(TasksContext);
const [locked, setLocked] = useState([]);


const [sifre, setSifre] = useState('');
const params= useParams();

const members= params.member;

// burda odaya giriş aksiyonu postları yapılıyor


const Handlesifre=(event)=>{

	setSifre(event.target.value);
	
	
	}
	
	const [datadondur, setDatadondur] = useState([]);
	
	const HandleGiris=async(event)=>{
	
		event.preventDefault();
	
	try {
		
	const respon=await axios.get(apiurl+`admin/api/Odasifre.php?email=${users.email}&room_name=${members}&password=${sifre}`);
	
	setDatadondur(respon.data.data);
	//console.log(respon.data.data);
	if (respon.data.data) {
		
		setOdamacik(true);
	alert("Başarı ile odaya girş yaptınız");
	}
	else{
	
		setOdamacik(false);
	alert("Şifre hatalıdır veya onaylı üye değilsiniz..");
	}
	
	} catch (error) {
	
		console.log("Hata:"+error);
	
	}
	
	
	}
	
	
	// burda odaya giriş aksiyonu postları yapılıyor






const Lock=async()=>{

const lock= await axios.get(apiurl+`/admin/api/RoomapiLock.php?url=${members}`);

setLocked(lock.data.data);


}

useEffect(()=>{

Lock();



},[]);

// lock





    return ( <>


{odamacik ?
<div className="container-fluid h-100">
			<div className="row justify-content-center h-100">
				<div className="col-md-12 col-xl-12 chat">
			
					{/* 
					<div className="card mb-sm-3 mb-md-0 contacts_card">
					<div className="card-header">
						<div className="input-group">
							<input type="text" placeholder="Search..." name="" className="form-control search"/>
							<div className="input-group-prepend">
								<span className="input-group-text search_btn"><i className="fas fa-search"></i></span>
							</div>
						</div>
					</div>
					
					<div className="card-body contacts_body">
						<ul className="contacts">

						
				 
               
						<li>
							<div className="d-flex bd-highlight">
								<div className="img_cont">
									<img src="https://2.bp.blogspot.com/-8ytYF7cfPkQ/WkPe1-rtrcI/AAAAAAAAGqU/FGfTDVgkcIwmOTtjLka51vineFBExJuSACLcBGAs/s320/31.jpg" className="rounded-circle user_img"/>
									<span className="online_icon offline"></span>
								</div>
								<div className="user_info">
									<span>Taherah Big</span>
									<p>Taherah left 7 mins ago</p>
								</div>
							</div>
						</li>
						<li>
							<div className="d-flex bd-highlight">
								<div className="img_cont">
									<img src="https://i.pinimg.com/originals/ac/b9/90/acb990190ca1ddbb9b20db303375bb58.jpg" className="rounded-circle user_img"/>
									<span className="online_icon"></span>
								</div>
								<div className="user_info">
									<span>Sami Rafi</span>
									<p>Sami is online</p>
								</div>
							</div>
						</li>
						<li>
							<div className="d-flex bd-highlight">
								<div className="img_cont">
									<img src="" className="rounded-circle user_img"/>
									<span className="online_icon offline"></span>
								</div>
								<div className="user_info">
									<span>Nargis Hawa</span>
									<p>Nargis left 30 mins ago</p>
								</div>
							</div>
						</li>
						<li>
							<div className="d-flex bd-highlight">
								<div className="img_cont">
									<img src="https://static.turbosquid.com/Preview/001214/650/2V/boy-cartoon-3D-model_D.jpg" className="rounded-circle user_img"/>
									<span className="online_icon offline"></span>
								</div>
								<div className="user_info">
									<span>Rashid Samim</span>
									<p>Rashid left 50 mins ago</p>
								</div>
							</div>
						</li>
                      

						</ul>
					</div>
				
					<div className="card-footer"></div>
				</div></div>

				*/}
				</div>
				<div className="col-md-12 col-xl-12 chat">
					<div className="card cardKapsayici">
						<div className="card-header msg_head">
							<div className="d-flex bd-highlight">
								<div className="img_cont">
									<img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" className="rounded-circle user_img"/>
									<span className="online_icon"></span>
								</div>
								<div className="user_info">
									<span>Chat with Khalid</span>
									<p>1767 Messages</p>
								</div>
								<div className="video_cam">
									<span><i className="fas fa-video"></i></span>
									<span><i className="fas fa-phone"></i></span>
									
								</div>
								<div className="video_cam"> {/* admin role mesaj temizleme */}
                             
							 <RoomadminMap users={users}/>
							 </div>
							
							</div>
							<span id="action_menu_btn"><i className="fas fa-ellipsis-v"></i></span>
							<div className="action_menu">
								<ul>
									<li><i className="fas fa-user-circle"></i> View profile</li>
									<li><i className="fas fa-users"></i> Add to close friends</li>
									<li><i className="fas fa-plus"></i> Add to group</li>
									<li><i className="fas fa-ban"></i> Block</li>
								</ul>
							</div>
						</div>
						
						<div className="card-body msg_card_body" id="home">
                     
						<RoomschatMap users={users} />
						{/*
							<div className="d-flex justify-content-start mb-4">
								<div className="img_cont_msg">
									<img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" className="rounded-circle user_img_msg"/>
								</div>
								
								<div className="msg_cotainer">
									

								
									<span className="msg_time">8:40 AM, Today</span>
								</div>
                                
							</div>
                            */}    
							
							<div className="d-flex justify-content-end mb-4">
								{/*
								<div className="msg_cotainer_send">
									Hi Khalid i am good tnx how about you?
									<span className="msg_time_send">8:55 AM, Today</span>
								</div>
								<div className="img_cont_msg">
								</div>
									*/
						}
							</div>

						
							{/* 
							<div className="d-flex justify-content-start mb-4">
								<div className="img_cont_msg">
									<img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" className="rounded-circle user_img_msg"/>
								</div>
								<div className="msg_cotainer">
									I am good too, thank you for your chat template
									<span className="msg_time">9:00 AM, Today</span>
								</div>
							</div>
							<div className="d-flex justify-content-end mb-4">
								<div className="msg_cotainer_send">
									You are welcome
									<span className="msg_time_send">9:05 AM, Today</span>
								</div>
								<div className="img_cont_msg">
								</div>
							</div>
							<div className="d-flex justify-content-start mb-4">
								<div className="img_cont_msg">
									<img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" className="rounded-circle user_img_msg"/>
								</div>
								<div className="msg_cotainer">
									I am looking for your next templates
									<span className="msg_time">9:07 AM, Today</span>
								</div>
							</div>
							<div className="d-flex justify-content-end mb-4">
								<div className="msg_cotainer_send">
									Ok, thank you have a good day
									<span className="msg_time_send">9:10 AM, Today</span>
								</div>
								<div className="img_cont_msg">
								</div>
							</div>
							<div className="d-flex justify-content-start mb-4">
								<div className="img_cont_msg">
									<img src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg" className="rounded-circle user_img_msg"/>
								</div>
								<div className="msg_cotainer">
									Bye, see you
									<span className="msg_time">9:12 AM, Today</span>
								</div>
							</div>
							*/}
						</div>
						
						{/* form yeri burasi */}

						{locked.url == members ?<div><span className="beyaz_write">Bu sayfa kilitlidir</span></div>:<div>
						<RoomsmuteMap users={users} />
						</div>}
					</div>
				</div>
			</div>
		</div>   
:<div>
	
<div className="formkutum">
	
	<form>

<input className="sifre" placeholder="buraya oda şifrenizi giriniz" value={sifre} onChange={Handlesifre} />
<button className="odabutton" onClick={HandleGiris}>Odaya giriş</button>
	</form>
	
	</div>	
	
	
	
	</div>}
    </> );
}

export default Roomhome;