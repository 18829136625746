import { useContext } from "react";
import TasksContext from "../task";
import EmojiShow from "./EmojiShow";


function EmojiMap({users,mutlu}) {
   
 const {emojiall} = useContext(TasksContext);
//console.log(emojiler);
   
   return (<div>

{

emojiall.map((emoji,index)=>{

return <EmojiShow key={index} emoji={emoji} users={users}/>;

})

}

    </div>  );
}

export default EmojiMap;