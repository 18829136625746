


import { useContext, useEffect,useState } from "react";
import axios from 'axios';
import TasksContext from "./task";
import OdaShow from "./OdaShow";


function OdaMap({odamagazin,users}) {



    
    return ( <div>


{

odamagazin.map((oda,index)=>{

return <OdaShow key={index} oda={oda} users={users}/>;


})

}



    </div> );
}

export default OdaMap;