import { useContext } from "react";
import TasksContext from "../task";
import {useParams} from 'react-router-dom';
import axios from "axios";
import { useState } from "react";
function AdminShow({users,admindelete}) {
    const {PmPost,apiurl} = useContext(TasksContext);

const [textdelete, setTextdelete] = useState([]);
const params= useParams();

	const members= params.member;
	const members_two= params.membertwo;

//console.log(apiurl);
const HandleDelete=async(event)=>{

event.preventDefault();

    const configs={

        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        
        };
    /*
    const nesnem={
    
     ozel_mesaj:users.displayName+":"+"    "+text
    
    
    
    }
    */
const text= await axios.post(apiurl+`admin/api/Pmdelete.php?sender_chat_id=${members}&receiving_chat_id=${members_two}`);
    
setTextdelete(text);

alert("Mesajlar temizlendi..");


}


    
    return (  <>


<form>
<button className="btn btn-warning" onClick={HandleDelete} type="submit">Mesajları temizle</button>

</form>





    </>);
}

export default AdminShow;