
import TasksContext from "../task";
import { useContext } from "react";
import PmMuteShow from './PmMuteShow';

function PmMuteMap({users}) {
    const {mute} = useContext(TasksContext);

    
    return ( <div>
{

mute.map((mutlu,index)=>{

return <PmMuteShow key={index} mutlu={mutlu} users={users}/>;

})

}


    </div> );
}

export default PmMuteMap;