import { useContext, useEffect, useState } from "react";
import TasksContext from "../task";
import RoomsmuteShow from "./RoomsmuteShow";
import axios from "axios";

function RoomsmuteMap({users}) {
    
const {mute} = useContext(TasksContext);





    return ( <>



 <RoomsmuteShow  users={users}/>


    </> );
}

export default RoomsmuteMap;