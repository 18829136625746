
import RoomschatShow from './RoomschatShow';
function RoomschatMapping({nik,users}) {
    
    
    return ( <>

{

nik.map((n,index)=>{

return <RoomschatShow key={index} n={n}  users={users}/>;

})

}


    </> );
}

export default RoomschatMapping;