import MuteShow from "./MuteShow";
import TasksContext from "../task";
import { useContext } from "react";


function MuteMap({users}) {
    
const {mute} = useContext(TasksContext);



return ( <div>

{

mute.map((mutlu,index)=>{

return <MuteShow key={index} mutlu={mutlu} users={users}/>;

})

}

    </div> );
}

export default MuteMap;