
import { useContext, useEffect,useState } from "react";

import axios from 'axios';
import { Link } from "react-router-dom";
import EngelodaMap from './engelleoda/EngelodaMap';
import TasksContext from "./task";
function OdaShow({oda,users}) {

    const {apiurl,PmPost} = useContext(TasksContext);

    const [open, setOpen] = useState([]);
    
//console.log(oda.aciklama);

const [emojiler, setEmojiler] = useState([]);
    const EmojiList=async(id)=>{
    
    const emoji = await axios.get(apiurl+`admin/api/EmojiList.php?code=${id}`);
    
    setEmojiler(emoji.data.data);
    
    //console.log(emoji.data.data);
    
    
    
    }
    useEffect(()=>{
    
      EmojiList(oda.description);
      
      },[]);

    

return ( <>


<div className="d-flex justify-content-start mb-4">
    <div className="img_cont_msg">
        
        <img src="https://w7.pngwing.com/pngs/806/764/png-transparent-sylvester-jr-tweety-cat-looney-tunes-cat.png" className="rounded-circle user_img_msg"/>
    
</div>
<div className="KullaniciAdi"><span className="usernameWrite">{oda.username}</span></div>
<div className="msg_cotainer">


{ emojiler.name_emoji == oda.description?<div>

<img src={emojiler.picture_emoji} className="iconEmoji"/>
</div>:<div>
    
    {oda.description}
    </div>}

   
</div>
<EngelodaMap users={users} oda={oda}/>
<span className="msg_time"> AM today </span>
    </div>












<div>

</div>
    </> );
}

export default OdaShow;