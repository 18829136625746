import {  useContext } from "react";
import TasksContext from "../task";
import PbanShow from "./PbanShow";


function PbanMap({admin,setAdmin,Setloading,setKullanici}) {

const {ipban}= useContext(TasksContext);




    return ( <div>
{

ipban.map((ip,index)=>{

return <PbanShow key={index} ip={ip} admin={admin} setAdmin={setAdmin} Setloading={Setloading} setKullanici={setKullanici}/>;


})

}



    </div> );
}

export default PbanMap;