
import { useState } from "react";
import TasksContext from "../task";
import { useContext } from "react";
import { Link } from "react-router-dom";
import EngelleMap from "../engelle/EngelleMap";
function EngelodaShow({admin,users,oda}) {
    
    
    const {Engelle,EngelOpen,Engellelog,ApiOpen,PmPost}= useContext(TasksContext);


    const [gizli, setGizli] = useState('');
    
    
    const [gizliopen, setGizliopen] = useState('');
    
    
    
    
    
    
    
    
    const HandleEngel=(event)=>{
    
    event.preventDefault();
    
    setGizli(oda.uid);
    
    Engelle(oda.uid);
    Engellelog(oda.username,users.displayName);
    alert("Kullanıcı engellendi");
    }
    
    const HandleEngelOpen=(event)=>{
    
    event.preventDefault();
    
    EngelOpen(oda.uid);
    setGizliopen(oda.uid);
    ApiOpen(oda.username,users.displayName);
    alert("Kullanıcının engeli açıldı");
    }

    const pm= "/Pm/";
    const ben="/";

    const Mesaj=(event)=>{

      event.preventDefault();
      
          PmPost(users.displayName,users.uid,oda.username,oda.uid);
      
      alert("İstek gönderildi kabul edilmesi bekleniyor");
      
      }

    return (  <>

{

admin.email == users.email ?<div>
    <div className="formBox">
<form>
<input type="hidden" value={gizli}/>
    <button className="btn btn-success" onClick={HandleEngelOpen}>Engeli aç</button>
</form>

</div>
<div  className="formBox">
<form>
<input type="hidden" value={gizliopen}/>
    <button className="btn btn-danger" onClick={HandleEngel}>Engelle</button>
</form>
</div>


</div>:<div>

</div>

    }




    </>);
}

export default EngelodaShow;