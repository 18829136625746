
import { useState } from "react";
import TasksContext from "../task";
import { useContext } from "react";

function EmojiShow({emoji}) {


			

// open emoji



    return ( <div>

<div>

<div className="iconEmoji"><img src={emoji.picture_emoji} className="iconEmojiPicture"  value={emoji.name_emoji}/></div>
<div className="Emojiwrite" id="ici">{emoji.name_emoji}</div>


</div>


    </div> );
}

export default EmojiShow;