
import { useContext,useState } from "react";
import TasksContext from "../task";
import {useParams} from 'react-router-dom';
import axios from "axios";

function Roomadminshow({users,admin}) {
   
    const {MessageDelete,MessageDeleteoda,host,apiurl} = useContext(TasksContext);

    const params= useParams();

    const members= params.member;
    
const [del, setDel] = useState([]);

    const HandleDelete=async(event)=>{
    try {
        
   
event.preventDefault();
   
 const afterDeletingTasks= await axios.post(apiurl+`admin/api/RoomsDelete.php?id=${members}`);

//const dele= await axios.delete(host+`${members}`);
  setDel(afterDeletingTasks.data.data);

} catch (error) {
       
    console.log("Hatamız:"+error);

}
    
    }

   
   return (<>
{

admin.email  == users.email?<div>

<form>
<button className="btn btn-success" onClick={HandleDelete} type="submit">OdaTEMİZLE</button>

</form>

</div>:<div>



</div>

}
        
    </>  );
}

export default Roomadminshow;