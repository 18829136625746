import ChatShows from "./ChatShows";

function ChatMaps({pmId,users,data}) {
    



    return (<div>

{

pmId.map((nes,index)=>{

return <ChatShows key={index} nes={nes} users={users} data={data}/>;

})

}



    </div>  );
}

export default ChatMaps;