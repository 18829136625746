import { useContext } from "react";
import TasksContext from "../task";
import EngelodaShow from "./EngelodaShow";


function Engeloda({users,oda}) {
   
   const {admindelete}=useContext(TasksContext);


   return ( <div>

{

admindelete.map((admin,index)=>{

return <EngelodaShow key={index} admin={admin} users={users} oda={oda}/>


})

}

    </div> );
}

export default Engeloda;